"use strict";

(function () {
  var controllers = {
    window: window,
    document: document,
    NavigationController: NavigationController,
    SliderController: SliderController
  };

  (function (controllers) {
    // scroll to top
    gsap.registerPlugin(ScrollToPlugin);
    document.querySelector('.footer--backtotop').addEventListener('click', function (e) {
      e.preventDefault();
      gsap.to(window, {
        duration: 0.33,
        scrollTo: {
          y: 0,
          autoKill: true
        },
        ease: "power2"
      });
    });
    var $footerTopbar = document.querySelector('.footer--topbar');
    $footerTopbar.addEventListener('click', function (e) {
      e.preventDefault();
      gsap.to(window, {
        duration: 0.33,
        scrollTo: {
          y: $footerTopbar.offsetTop,
          autoKill: true
        },
        ease: "power2"
      });
    });
    var navigationController, sliderController; // init controllers

    controllers.document.addEventListener('DOMContentLoaded', function () {
      createNavigationController();
      createSliderController();
    }); // navigation

    var createNavigationController = function createNavigationController() {
      navigationController = new controllers.NavigationController();
      navigationController.init();
    }; // slider


    var createSliderController = function createSliderController() {
      sliderController = new controllers.SliderController();
      sliderController.init();
    }; // touch controller


    var touchStartX;
    var swipeDirection;
    var onlongtouch;
    var nextSlide;
    var prevSlide;
    var onswipe;
    var pauseTimer;
    var unpauseTimer;
    var touchtimer;
    var touchduration = 200;
    var wasLongTouch = false;

    function touchStart(e) {
      touchStartX = e.touches[0].clientX;
      touchtimer = setTimeout(onlongtouch, touchduration);
      pauseTimer();
    }

    function touchMove(e) {
      var touchEndX = e.changedTouches[0].clientX;
      var diffX = touchEndX - touchStartX;

      if (diffX > 100) {
        swipeDirection = 'right';
      } else if (diffX < -100) {
        swipeDirection = 'left';
      }
    }

    function touchEnd(e) {
      if (swipeDirection != undefined) {
        console.log('swiped ' + swipeDirection);
        swipeDirection = undefined;
      }

      if (touchtimer) {
        clearTimeout(touchtimer);
      }

      if (wasLongTouch) {
        unpauseTimer();
        wasLongTouch = false;
      } else {
        // was shortTouch
        if (touchStartX < window.innerWidth / 2) {
          console.log(touchStartX);
          prevSlide();
        } else {
          nextSlide();
        }

        wasLongTouch = false;
      }
    }

    pauseTimer = function pauseTimer() {
      console.log('pause timer');
      sliderController.pause();
    };

    unpauseTimer = function unpauseTimer() {
      console.log('unpause timer');
      sliderController.resume();
    };

    onswipe = function onswipe(direction) {
      console.log('swiped ' + direction);
    };

    onlongtouch = function onlongtouch() {
      wasLongTouch = true;
    }; // nextSlide


    nextSlide = function nextSlide() {
      console.log('go to next Slide');
    }; // prevSlide


    prevSlide = function prevSlide() {
      console.log('go to previous Slide');
    };

    var $main = document.querySelector('.main');
    $main.ontouchmove = touchMove;
    $main.ontouchstart = touchStart;
    $main.ontouchend = touchEnd;
  })(controllers);
})();